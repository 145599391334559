<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap border-bottom py-2">
        <div class="d-flex align-items-center mb-3">
          <h1 class="h3">{{ $t('sideBar.logs') }}/</h1>
          <router-link to="requests" class="h3">
            {{ $t('sideBar.requests') }}
          </router-link>
          <small v-if="paginationData && paginationData" tabindex="-1" class="form-text text-muted ml-3">
            {{ $t('title.showing') }}
            {{ parseInt(paginationData.total) >= parseInt(paginationData.per_page) ? paginationData.per_page : paginationData.total }} {{ $t('title.of') }}
            {{ paginationData.total }} {{ $t('title.entries') }}
          </small>
        </div>
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <b-form-select v-model="filters.selectedLevelName" :options="levelNamesList" class="w-auto" @change="pageClick(1)" />
            <MultiSelect
              v-model="filters.selectedUser"
              label="name"
              track-by="id"
              :options="filters.filterUsersList"
              class="w-270px"
              :placeholder="$t('table.selectUser')"
              @input="pageClick(1)"
            />
          </div>

          <search-input
            v-model="searchElements"
            :show-selector="true"
            :selected-search-type.sync="searchType"
            :options="searchFieldsList"
            @searchTable="pageClick(1)"
          />
        </div>
      </div>
      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th scope="col" style="min-width: 50px">
              <sort-arrows :sort-string="sortString" :column="'datetime,'" @click="sortTable('datetime,', $event)" />
              {{ $t('table.date') }}, {{ $t('table.time') }}
            </th>
            <th>{{ $t('table.level') }}</th>
            <th>{{ $t('table.user') }}</th>
            <th>{{ $t('table.status') }}</th>
            <th>{{ $t('table.requestId') }}</th>
            <th>{{ $t('table.message') }}</th>
            <th>{{ $t('reports.dataType') }}</th>
          </thead>
          <tbody>
            <tr v-if="logsListStatus !== 'success'">
              <td colspan="7"><SpinnerLoader :loading="logsListStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                {{ row.datetime ? new Date(row.datetime).toLocaleString() : '' }}
              </td>
              <td>{{ row.level }}</td>
              <td>
                <router-link
                  v-if="row.user"
                  :to="`users?q=${row.user.login}&search_type=login`"
                  :title="`${$t('table.clickToOpenOnUsersPage')}. \n${$t('table.userData')}: \n${row.user.email} \n${row.user.login}`"
                >
                  {{ row.user.name }}
                </router-link>
              </td>
              <td>{{ row.level_name }}</td>
              <td>{{ row.request_id }}</td>
              <td>{{ row.message }}</td>
              <td>
                <b-button variant="outline-primary" size="sm" @click="loadLogInfo(row.id)">{{ $t('table.openVerb') }}</b-button>
              </td>
            </tr>
            <tr v-if="logsListStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="7">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->
    </div>
    <b-modal
      id="log-details-modal"
      :title="$t('table.logDetails')"
      :ok-title="$t('table.close')"
      :cancel-title="$t('table.cancel')"
      :busy="logDataStatus === 'loading'"
      size="lg"
      scrollable
    >
      <SpinnerLoader v-if="logDataStatus !== 'success'" :loading="logDataStatus" />
      <div v-else-if="logData && logData.data" class="overflow-auto">
        <pre style="white-space: pre-wrap">{{ logData.data.context && logData.data.context.data ? logData.data.context.data : $t('table.noContent') }}</pre>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader';
import PaginateWrapper from '@/components/PaginateWrapper';
import SearchDropdown from '@/components/SearchDropdown';
import SearchInput from '@/components/SearchInput';
import { mapGetters } from 'vuex';
import logs from '@/store/logs/index';
import SortArrows from '@/components/SortArrows';
import sortTableMixin from '@/mixins/sortTable';
import convertDate from '@/filters/convertDate';
import MultiSelect from '@/components/MultiSelect';

export default {
  name: 'Logs',
  components: { SpinnerLoader, PaginateWrapper, SearchDropdown, SearchInput, SortArrows, MultiSelect },
  filters: { convertDate },
  mixins: [sortTableMixin],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    search_type: { type: String, default: undefined },
    level_name: { type: String, default: undefined },
    selected_user: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      paginationData: null,
      searchElements: '',
      sortString: '-datetime,',
      filterPage: 1,
      selectedPerPage: '50',
      searchType: 'request_id',
      tableData: [],
      filters: {
        selectedLevelName: '',
        selectedUser: null,
        filterUsersList: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      logsList: 'getLogsList',
      logData: 'getLogData',
      logsListStatus: 'getLogsListStatus',
      logDataStatus: 'getLogDataStatus',
      users: 'getUsers',
    }),
    searchFieldsList() {
      return [
        { value: 'request_id', text: this.$i18n.t('table.requestId') },
        { value: 'level', text: this.$i18n.t('table.levelCode') },
        { value: 'message', text: this.$i18n.t('table.message') },
      ];
    },
    levelNamesList() {
      return [
        { value: '', text: this.$i18n.t('table.selectStatus'), disabled: true },
        { value: null, text: this.$i18n.t('table.all') },
        { value: 'EMERGENCY', text: 'EMERGENCY' },
        { value: 'ALERT', text: 'ALERT' },
        { value: 'CRITICAL', text: 'CRITICAL' },
        { value: 'ERROR', text: 'ERROR' },
        { value: 'WARNING', text: 'WARNING' },
        { value: 'NOTICE', text: 'NOTICE' },
        { value: 'INFO', text: 'INFO' },
        { value: 'DEBUG', text: 'DEBUG' },
      ];
    },
  },
  watch: {
    logsList() {
      this.setTableData();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule('logs')) this.$store.registerModule('logs', logs);
  },
  created() {
    document.title = this.$i18n.t('sideBar.logs') + ' – OpenMediaLogic';
  },
  mounted() {
    this.restoreProps();
  },
  destroyed() {
    this.$store.commit('clearLogs');
    this.$store.commit('clearUsersList');
    if (!module.hot && this.$store.hasModule('logs')) this.$store.unregisterModule('logs'); // don't unregister when HMR
  },
  methods: {
    async restoreProps() {
      //restore props
      await this.$store.dispatch('GET_USERS', { per_page: 2000 });
      if (this.users?.data) {
        this.filters.filterUsersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.users.data];
      }
      if (this.q) this.searchElements = this.q;
      if (this.on_page) this.filterPage = +this.on_page;
      if (this.per_page && this.per_page != '10') this.selectedPerPage = this.per_page;
      if (this.sort_mode) {
        this.sortString = this.sort_mode;
        if (this.sortString && this.sortString.slice(-1) !== ',') this.sortString += ',';
      }
      if (this.search_type) this.searchType = this.search_type;
      if (this.level_name) this.filters.selectedLevelName = this.levelNamesList.find((e) => e.value === this.level_name)?.value;
      if (this.selected_user) this.filters.selectedUser = this.filters.filterUsersList.find((u) => u.id === +this.selected_user);
      this.paramsData();
    },

    async paramsData() {
      if (this.tableData) this.tableData = [];
      await this.$store.dispatch('GET_LOGS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        sort: this.sortString,
        'filter[request_id]': this.searchElements !== '' && this.searchType === 'request_id' ? this.searchElements : null,
        'filter[level]': this.searchElements !== '' && this.searchType === 'level' ? this.searchElements : null,
        'filter[message]': this.searchElements !== '' && this.searchType === 'message' ? this.searchElements : null,
        'filter[level_name]': this.filters.selectedLevelName || undefined,
        'filter[user->id]': this.filters.selectedUser?.id,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    setTableData() {
      if (this.logsList) {
        this.tableData = this.logsList.data;
        this.paginationData = this.logsList.meta;
        if (this.filterPage > this.logsList.meta.last_page) {
          this.filterPage = this.logsList.meta.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    updateQuery() {
      if (
        this.$route.query.q !== this.searchElements ||
        this.$route.query.on_page != this.filterPage ||
        this.$route.query.per_page != this.selectedPerPage ||
        this.$route.query.sort != this.sortString ||
        this.$route.query.search_type != this.searchType ||
        this.$route.query.level_name != this.filters.selectedLevelName ||
        this.$route.query.selected_user != this.filters.selectedUser?.id
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            q: this.searchElements,
            sort: this.sortString,
            on_page: this.filterPage,
            per_page: this.selectedPerPage,
            search_type: this.searchType,
            level_name: this.filters.selectedLevelName,
            selected_user: this.filters.selectedUser?.id,
          },
        });
      }
    },

    async loadLogInfo(id) {
      this.$bvModal.show('log-details-modal');
      await this.$store.dispatch('GET_LOG_ID', {
        id,
        params: { visible: 'context' },
      });
    },
  },
};
</script>
