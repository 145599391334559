// VUEX DYNAMIC STORE MODULE
import Vue from 'vue';
import { getLogs, getLogId } from '../../api/apiServices';

export default {
  state: {
    logsList: null,
    logsListStatus: '',

    logData: null,
    logDataStatus: '',
  },
  mutations: {
    setLogsListData(state, payload) {
      state.logsListStatus = payload[0];
      Vue.set(state, 'logsList', payload[1] || null);
    },
    setLogData(state, payload) {
      state.logDataStatus = payload[0];
      Vue.set(state, 'logData', payload[1] || null);
    },

    clearLogs: (state) => {
      state.logsList = null;
      state.logsListStatus = '';
      state.logData = null;
      state.logDataStatus = '';
    },
  },
  actions: {
    // LOGS
    async GET_LOGS({ dispatch, commit }, payload) {
      commit('setLogsListData', ['loading']);
      await getLogs(payload)
        .then((res) => commit('setLogsListData', ['success', res.data]))
        .catch((e) => commit('setLogsListData', ['error']));
    },

    async GET_LOG_ID({ dispatch, commit }, payload) {
      commit('setLogData', ['loading']);
      await getLogId(payload.id, payload.params)
        .then((res) => commit('setLogData', ['success', res.data]))
        .catch((e) => commit('setLogData', ['error']));
    },
  },
  getters: {
    getLogsList: (state) => state.logsList,
    getLogData: (state) => state.logData,
    getLogsListStatus: (state) => state.logsListStatus,
    getLogDataStatus: (state) => state.logDataStatus,
  },
};
